<template>
  <div class="page flex-col">
    <div class="box_1 flex-col justify-end">
      <div class="group_1 flex-row">
        <img class="image_1" referrerpolicy="no-referrer"
          src="./assets/img/SketchPngf91bd4090c85f8052575aea6fa428f6c46b69be113c5ad1aaff9e50d60b02cd9.png" />
        <span class="text_1" style="cursor:pointer" @click="tabJump('sobrenosotros')">SOBRE&nbsp;NOSOTROS</span>
        <span class="text_2" style="cursor:pointer">QUIERO&nbsp;RETROALIMENTACIÓN</span>
        <span class="text_3" style="cursor:pointer" @click="tabJump('eliminarcuenta')">ELIMINAR&nbsp;CUENTA</span>
      </div>
      <div class="group_2 flex-row">
        <div class="section_1 flex-col"></div>
      </div>
    </div>
    <div class="box_2 flex-col">
      <div class="block_1 flex-col">
        <div class="text-wrapper_1">
          <span class="paragraph_1">
            Oh,&nbsp;Querido/a,
            <br />
          </span>
          <span class="text_4">Tiene&nbsp;Algún&nbsp;Problema?</span>
        </div>
        <span class="text_5">
          Infórmenos&nbsp;Y&nbsp;Haremos&nbsp;Lo&nbsp;Posible&nbsp;Por&nbsp;Resolverlo.&nbsp;Por&nbsp;Favor,&nbsp;Comuníquese&nbsp;Con&nbsp;Nosotros.
        </span>
        <div class="image-text_1 flex-row justify-between">
          <img class="label_1" referrerpolicy="no-referrer"
            src="./assets/img/SketchPngbf321b5b5dfbf07d93b82498f8435e427be2c605a3773798f5479a0bf2013754.png" />
          <div class="text-group_1">
            <span class="text_6">Número:</span>
            <span class="text_7">5577&nbsp;7489&nbsp;64</span>
          </div>
        </div>
      </div>
      <div class="image-text_2 flex-row justify-between">
        <img class="label_2" referrerpolicy="no-referrer"
          src="./assets/img/SketchPng9cd7fc09ac131cdd82acf6062b3f8f6be6bda241684c3e006e73585a9aeec071.png" />
        <div class="text-group_2">
          <span class="text_8">Correo:</span>
          <span class="text_9">&nbsp;servicio&#64;casacredito.mx</span>
        </div>
      </div>
      <div class="block_2 flex-col">
        <span class="text_10">Descripción&nbsp;del&nbsp;Problema</span>
        <!-- <div class="text-wrapper_2 flex-col"><span class="text_11">
          
          Por&nbsp;Favor&nbsp;Ingrese&nbsp;...</span></div> -->
        <!-- <va-textarea :editableText="form.questionDes"/> -->
        <van-field class="text-wrapper_2 flex-col editable-textarea " v-model="form.questionDes" type="textarea"
          rows="4" placeholder="Por Favor Ingrese ..." />
        <span class="text_12">Mi&nbsp;Solicitud</span>
        <!-- <div class="text-wrapper_3 flex-col">
          <span class="text_13">Por&nbsp;Favor&nbsp;Ingrese&nbsp;...</span></div> -->
        <van-field class="text-wrapper_2 flex-col editable-textarea " v-model="form.myRequest" type="textarea" rows="4"
          placeholder="Por Favor Ingrese ..." />
        <span class="text_14">Mi&nbsp;Información&nbsp;de&nbsp;Contacto</span>
        <!-- <span class="text_15">
          Por&nbsp;Favor&nbsp;Ingrese&nbsp;Su&nbsp;Número&nbsp;de&nbsp;Teléfono/Correo&nbsp;Electrónico
        </span> -->
        <div class="custom-form">
          <van-field class="text-wrapper_2 flex-col editable-textarea " v-model="form.myContact" type="textarea"
            rows="1" placeholder="Por Favor Ingrese Su Número de Teléfono/Correo Electrónico" />
        </div>

        <!-- <div class="block_3 flex-col"></div> -->
        <div style="cursor:pointer" @click="submit" class="text-wrapper_4 flex-col"><span class="text_16">Enviar</span>
        </div>
      </div>
    </div>
    <div class="box_3 flex-row">
      <img class="image_2" referrerpolicy="no-referrer"
        src="./assets/img/SketchPng70ef548af9605dc0d0c9219f5d8aa4ed26d4292df624286284d633478bec4bee.png" />
      <a style="text-decoration: underline;" class="text_17" :href="privateUrl">Política&nbsp;de&nbsp;privacidad</a>
      <span class="text_18">©&nbsp;2024&nbsp;CASACREDITO.&nbsp;TODOS&nbsp;LOS&nbsp;DERECHOS&nbsp;RESERVADOS.</span>
    </div>
  </div>
</template>
<script>
import { privateUrl } from "../../utils/constants.js";
export default {
  data() {
    return {
      privateUrl,
      editableText: 'Por&nbsp;Favor&nbsp;Ingrese...',
      constants: {},
      form: {
        // 问题描述
        questionDes: "",
        // 我的要求
        myRequest: "",
        // 我的联系方式
        myContact: ""
      }
    };
  },
  methods: {
    tabJump(name) {
      this.$router.push({ name })
    },
    // 提交成功，并清空表单数据
    submit() {
      this.form = { questionDes: "", myRequest: "", myContact: "" }
      this.showToast()
    },
    showToast() {
      this.$utils.showToast();
    }  
  }
};
</script>
<style scoped lang="css" src="./assets/index.response.css" />
