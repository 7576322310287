import Vue from 'vue'
import VueRouter from 'vue-router'
import quieroretroalimentación from '../views/quieroretroalimentación/index.vue'
import eliminarcuenta from '../views/eliminarcuenta/index.vue'
import sobrenosotros from '../views/sobrenosotros/index.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: "/sobrenosotros"
  },
  {
    path: '/quieroretroalimentación',
    name: 'quieroretroalimentación',
    component: quieroretroalimentación
  },
  {
    path: '/eliminarcuenta',
    name: 'eliminarcuenta',
    component: eliminarcuenta
  },
  {
    path: '/sobrenosotros',
    name: 'sobrenosotros',
    component: sobrenosotros
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
