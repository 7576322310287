<template>
  <div class="page flex-col">
    <div class="block_1 flex-col justify-end">
      <div class="box_1 flex-row">
        <img class="image_1" referrerpolicy="no-referrer"
          src="./assets/img/SketchPngf91bd4090c85f8052575aea6fa428f6c46b69be113c5ad1aaff9e50d60b02cd9.png" />
        <span class="text_1" style="cursor:pointer" @click="tabJump('sobrenosotros')">SOBRE&nbsp;NOSOTROS</span>
        <span class="text_2" style="cursor:pointer"
          @click="tabJump('quieroretroalimentación')">QUIERO&nbsp;RETROALIMENTACIÓN</span>
        <span class="text_3" style="cursor:pointer">ELIMINAR&nbsp;CUENTA</span>
      </div>
      <div class="box_2 flex-row">
        <div class="group_1 flex-col"></div>
      </div>
    </div>
    <div class="block_2 flex-col">
      <div class="box_3 flex-col">
        <div class="text-wrapper_1">
          <span class="paragraph_1">
            Lamentamos&nbsp;que&nbsp;quieras&nbsp;
            <br />
            eliminar&nbsp;tu&nbsp;cuenta.
            <br />
          </span>
          <span class="paragraph_2">
            Hay&nbsp;algo&nbsp;en&nbsp;lo&nbsp;que&nbsp;podamos ayudarte?
          </span>
        </div>
      </div>
      <span class="text_4">
        Tu&nbsp;opinión&nbsp;es&nbsp;muy&nbsp;importante&nbsp;para&nbsp;nosotros.&nbsp;Si&nbsp;decides&nbsp;quedarte,&nbsp;estaremos&nbsp;encantados&nbsp;de&nbsp;seguir&nbsp;ofreciéndote&nbsp;nuestros&nbsp;servicios.&nbsp;¡Esperamos&nbsp;verte&nbsp;de&nbsp;nuevo&nbsp;pronto!
      </span>
      <div class="box_4 flex-col">
        <span class="text_5">
          Ingresa&nbsp;tu&nbsp;número&nbsp;de&nbsp;teléfono&nbsp;y&nbsp;envía&nbsp;la&nbsp;información.&nbsp;Eliminaremos&nbsp;todos&nbsp;tus&nbsp;datos&nbsp;en&nbsp;el&nbsp;sistema,&nbsp;pero&nbsp;si&nbsp;tienes&nbsp;pedidos&nbsp;en&nbsp;curso,&nbsp;no&nbsp;podremos&nbsp;eliminar&nbsp;tus&nbsp;datos&nbsp;de&nbsp;inmediato.&nbsp;Necesitarás&nbsp;finalizar&nbsp;los&nbsp;pedidos&nbsp;y&nbsp;volver&nbsp;a&nbsp;enviar&nbsp;la&nbsp;solicitud.&nbsp;Una&nbsp;vez&nbsp;hecho&nbsp;esto,&nbsp;procederemos&nbsp;a&nbsp;eliminar&nbsp;tus&nbsp;datos&nbsp;de&nbsp;inmediato.
        </span>
        <span class="text_6">Número&nbsp;de&nbsp;Teléfono</span>
        <!-- <span class="text_7">+52</span> -->
          <div class="custom-form" > 
              <van-form  >  
              <van-field
              v-model="form.myContact"
    label="+52"
    label-width="2vw"
    placeholder=""
    type="tel"
    :maxlength="10"
      :rules="[  
        { required: true, message: '手机号不能为空', trigger: 'blur' },  
        { validator: checkPhoneNumber, trigger: ['blur', 'change'] }  
      ]"  
  />        
              </van-form>
        </div>
        <!-- <div class="box_5 flex-col"></div> -->
        <img class="image_2" referrerpolicy="no-referrer"
          src="./assets/img/SketchPngd7106496abbc7fdcc8a97f8dd4e6d3b508b45f0d475f4cd0d5d12905580dbc01.png" />
        <div style="cursor:pointer" @click="submit" class="text-wrapper_2 flex-col"><span class="text_8">Enviar</span>
        </div>
      </div>
    </div>
    <div class="block_3 flex-row">
      <img class="image_3" referrerpolicy="no-referrer"
        src="./assets/img/SketchPng70ef548af9605dc0d0c9219f5d8aa4ed26d4292df624286284d633478bec4bee.png" />
      <a class="text_9" style="text-decoration: underline;" :href="privateUrl">Política&nbsp;de&nbsp;privacidad</a>
      <span class="text_10">©&nbsp;2024&nbsp;CASACREDITO.&nbsp;TODOS&nbsp;LOS&nbsp;DERECHOS&nbsp;RESERVADOS.</span>
    </div>
  </div>
</template>
<script>
import { privateUrl } from "../../utils/constants.js";
export default {
  data() {
    return {
      phoneNumber: '',
      phonePrefix: '+52', // 设置你的默认电话前缀
      privateUrl,
      constants: {},
      form: {
        // 我的联系方式
        myContact: ""
      } 
    } 
  },
  methods: {  
     //   此处使用函数校验
    validator(val) {
      return /^\d{10}$/.test(val);
    },
     onFailed(errorInfo) {
      console.log('failed', errorInfo);
    },
   checkPhoneNumber(rule, value, callback) {  
    console.log("value", value);
    
      if (!/^\d{10}$/.test(value)) {  
        callback(new Error('手机号格式不正确，应为10位数字'));  
      } else {  
        callback();  
      }  
    },  
     handleInput(event) {  
      this.phoneNumber = event.target.value;  
      // 可以在这里添加验证逻辑  
    },  
    focusInput() {  
      this.$refs.realInput.focus();  
    },  
    tabJump(name) {
      this.$router.push({ name })
    },
    // 提交成功，并清空表单数据
    submit() {
      this.form = { phoneNumber: "" }
      this.showToast()
    },
    validatePhone() {
      console.log(1)
      const phonePattern = /^[0-9]{11}$/; // 只允许11位数字
      if (!this.phone.match(phonePattern)) {
        this.errorMessage = '手机号格式错误';
      } else {
        this.errorMessage = '';
      }
    },
    showToast() {
      // 简单的调用  
      // 带配置的调用  
      this.$utils.showToast();
    }  
  }
}
</script>
<style scoped lang="css" src="./assets/index.response.css" />
<style>
.van-toast {
  /* 增加顶部的边距 */
  top: 12vh;
  background-color: #effafb;
  color: #3b678b;
    width: 10.5vw!important;
    height: 3vh;
    border: 0.24vh solid #92c4c4;
}
.van-toast__text{
  display: flex;
}
/* .svg-icon {
    background-image: url(./assets/img/checked.png);
width: 0.8vw;
    height: 1.7vh;
    background-size: cover;
    display: inline-block;
    margin-right: 0.3vw;
    display: inline-block;
} */
.van-toast__text {
    display: flex;
    position: relative;
}
.van-toast__text img {
    width: 0.8vw;
    height: 0.8vw;
    position: absolute;
    left: -1.1vw;
    top: 0.3vh;
}
</style>