<template>
  <div class="page flex-col">
    <div class="group_1 flex-col justify-end">
      <div class="group_24 flex-row">
        <img
          class="image_1"
          referrerpolicy="no-referrer"
          src="./assets/img/SketchPngf91bd4090c85f8052575aea6fa428f6c46b69be113c5ad1aaff9e50d60b02cd9.png"
        />
        <span class="text_1" style="cursor:pointer" >SOBRE&nbsp;NOSOTROS</span>
        <span class="text_2" style="cursor:pointer"  @click="tabJump('quieroretroalimentación')">QUIERO&nbsp;RETROALIMENTACIÓN</span>
        <span class="text_3" style="cursor:pointer" @click="tabJump('eliminarcuenta')">ELIMINAR&nbsp;CUENTA</span>
      </div>
      <div class="group_25 flex-row"><div class="group_2 flex-col"></div></div>
    </div>
    <div class="box_20 flex-col">
      <span class="paragraph_1">
        ¡SENCILLO,&nbsp;CÓMODO,&nbsp;RÁPIDO,&nbsp;
        <br />
        SEGURO,&nbsp;CONFIABLE!
      </span>
      <div class="section_5 flex-row justify-between">
        <div class="box_21 flex-col justify-between">
          <div style="cursor:pointer" class="text-wrapper_1 flex-col"><a class="text_4"  :href="appUrl">Descargar&nbsp;App</a></div>
          <img
            class="image_2"
            referrerpolicy="no-referrer"
            src="./assets/img/SketchPng28576932e52cdeac69b566ddc0439d8f6fc7dd33e5987e6a7471e140de86576d.png"
          />
        </div>
        <div class="box_22 flex-col justify-between">
          <div class="image-wrapper_5 flex-row justify-between">
            <img
              class="image_4"
              referrerpolicy="no-referrer"
              src="./assets/img/SketchPnge9471e51b7660c79722a068e9ab89a26a1bfe8fb7a6abd9552e63dfbf3b5afdb.png"
            />
            <img
              class="image_5"
              referrerpolicy="no-referrer"
              src="./assets/img/SketchPng3a33c1489253f0d6fbb1f3acbf2728dd6a33ebc605355fc2b75944e752618907.png"
            />
          </div>
          <div class="image-wrapper_3 flex-col">
            <img
              class="label_1"
              referrerpolicy="no-referrer"
              src="./assets/img/SketchPngd5ee9b190676af79e19098ee1ba20255c7b1f2bd172e27554bd5278fa51652ef.png"
            />
          </div>
        </div>
      </div>
      <div class="text-group_14 flex-col justify-between">
        <span class="text_5">ELEGIRNOS</span>
        <span class="text_6">RAZONES&nbsp;PARA&nbsp;ELEGIRNOS</span>
      </div>
      <div class="group_5 flex-col"></div>
      <div class="section_6 flex-row justify-between">
        <div class="group_26 flex-col justify-between">
          <div class="group_8 flex-col">
            <div class="group_27 flex-row justify-between">
              <span class="paragraph_2">
                Proceso&nbsp;de&nbsp;solicitud&nbsp;
                <br />
                conveniente
              </span>
              <img
                class="image_6"
                referrerpolicy="no-referrer"
                src="./assets/img/SketchPngd7dc8ffcdb4c3e5b75053bd4dd6062e9a5019b8a1cfe5733c320f4194958a231.png"
              />
            </div>
            <span class="text_7">
              Nuestro&nbsp;proceso&nbsp;de&nbsp;solicitud&nbsp;de&nbsp;préstamos&nbsp;es&nbsp;simple&nbsp;y&nbsp;rápido,&nbsp;los&nbsp;usuarios&nbsp;pueden&nbsp;presentar&nbsp;la&nbsp;solicitud&nbsp;en&nbsp;línea&nbsp;sin&nbsp;necesidad&nbsp;de&nbsp;documentos&nbsp;en&nbsp;papel&nbsp;y&nbsp;múltiples&nbsp;entrevistas.
            </span>
          </div>
          <div class="group_10 flex-col">
            <div class="box_23 flex-row justify-between">
              <span class="paragraph_3">
                Tasas&nbsp;de&nbsp;interés&nbsp;
                <br />
                competitivas
              </span>
              <img
                class="image_7"
                referrerpolicy="no-referrer"
                src="./assets/img/SketchPng7ee9c1cfb7257ac5db9059c8f62cda0102efc57ae86cc6d7a883bc55a8a3dd8b.png"
              />
            </div>
            <span class="text_8">
              Nuestras&nbsp;tasas&nbsp;de&nbsp;interés&nbsp;son&nbsp;competitivas&nbsp;en&nbsp;el&nbsp;mercado,&nbsp;ayudando&nbsp;a&nbsp;los&nbsp;usuarios&nbsp;a&nbsp;ahorrar&nbsp;en&nbsp;gastos&nbsp;de&nbsp;intereses.
            </span>
          </div>
          <div class="group_12 flex-col">
            <div class="group_28 flex-row justify-between">
              <span class="paragraph_4">
                Servicio&nbsp;al&nbsp;cliente&nbsp;
                <br />
                de&nbsp;calidad
              </span>
              <img
                class="image_8"
                referrerpolicy="no-referrer"
                src="./assets/img/SketchPng8a792d02c9447f34b1fa6eb142ea390df048f5a4b69060c7029401738a7be0a8.png"
              />
            </div>
            <span class="text_9">
              Nuestro&nbsp;equipo&nbsp;de&nbsp;atención&nbsp;al&nbsp;cliente&nbsp;es&nbsp;profesional&nbsp;y&nbsp;amable,&nbsp;proporcionando&nbsp;ayuda&nbsp;oportuna&nbsp;y&nbsp;profesional,&nbsp;y&nbsp;resolviendo&nbsp;dudas&nbsp;y&nbsp;problemas&nbsp;de&nbsp;los&nbsp;usuarios.
            </span>
          </div>
          <div class="group_13 flex-col">
            <div class="group_29 flex-row justify-between">
              <span class="paragraph_5">
                Requisitos&nbsp;de&nbsp;crédito&nbsp;
                <br />
                flexibles
              </span>
              <img
                class="image_9"
                referrerpolicy="no-referrer"
                src="./assets/img/SketchPng8f105efce12c80925cc1f1c718edb8ae73191b4237fa3bc8ca3aafafecde374e.png"
              />
            </div>
            <span class="text_10">
              Apoyamos&nbsp;a&nbsp;usuarios&nbsp;con&nbsp;diversos&nbsp;historiales&nbsp;de&nbsp;crédito,&nbsp;permitiendo&nbsp;la&nbsp;solicitud&nbsp;de&nbsp;nuestros&nbsp;servicios&nbsp;de&nbsp;préstamo&nbsp;sin&nbsp;importar&nbsp;el&nbsp;estado&nbsp;del&nbsp;crédito.
            </span>
          </div>
        </div>
        <div class="group_30 flex-col justify-between">
          <div class="box_3 flex-col">
            <div class="section_7 flex-row justify-between">
              <img
                class="image_10"
                referrerpolicy="no-referrer"
                src="./assets/img/SketchPng0afb5c88289e1b82b05b63fe0b3687c69b6acbbab69cf3230cb8505d2c66877b.png"
              />
              <span class="paragraph_6">
                Opciones&nbsp;de&nbsp;pago&nbsp;
                <br />
                flexibles
              </span>
            </div>
            <span class="text_11">
              Ofrecemos&nbsp;diversas&nbsp;formas&nbsp;y&nbsp;períodos&nbsp;de&nbsp;pago&nbsp;para&nbsp;satisfacer&nbsp;las&nbsp;necesidades&nbsp;y&nbsp;planes&nbsp;financieros&nbsp;de&nbsp;los&nbsp;usuarios.
            </span>
          </div>
          <div class="box_4 flex-col">
            <div class="group_31 flex-row justify-between">
              <img
                class="image_11"
                referrerpolicy="no-referrer"
                src="./assets/img/SketchPng97836b092279b38a9116f1e15f10881e8f802a5beb4fbc7ef5967c920bc2d192.png"
              />
              <span class="paragraph_7">
                Aprobación&nbsp;y&nbsp;
                <br />
                desembolso&nbsp;rápidos
              </span>
            </div>
            <span class="text_12">
              Prometemos&nbsp;una&nbsp;aprobación&nbsp;rápida&nbsp;y&nbsp;desembolsar&nbsp;el&nbsp;monto&nbsp;del&nbsp;préstamo&nbsp;en&nbsp;corto&nbsp;tiempo&nbsp;a&nbsp;la&nbsp;cuenta&nbsp;del&nbsp;usuario,&nbsp;satisfaciendo&nbsp;necesidades&nbsp;de&nbsp;fondos&nbsp;urgentes.
            </span>
          </div>
          <div class="box_6 flex-col">
            <div class="box_24 flex-row justify-between">
              <img
                class="image_12"
                referrerpolicy="no-referrer"
                src="./assets/img/SketchPng4e157176048efee960219a76ab6c265b60b7837b5576453e34fdd1d080ccae51.png"
              />
              <span class="paragraph_8">
                Estructura&nbsp;de&nbsp;tarifas&nbsp;
                <br />
                transparente
              </span>
            </div>
            <span class="text_13">
              Proporcionamos&nbsp;una&nbsp;explicación&nbsp;clara&nbsp;de&nbsp;las&nbsp;tarifas,&nbsp;sin&nbsp;costos&nbsp;ocultos,&nbsp;asegurando&nbsp;que&nbsp;los&nbsp;usuarios&nbsp;conozcan&nbsp;todos&nbsp;los&nbsp;detalles&nbsp;de&nbsp;los&nbsp;costos.
            </span>
          </div>
          <div class="box_7 flex-col">
            <div class="box_25 flex-row justify-between">
              <img
                class="image_13"
                referrerpolicy="no-referrer"
                src="./assets/img/SketchPng1117d65fa1436ef344b3be99e546022762acc4d057f9599f8f81d265afc6c264.png"
              />
              <span class="paragraph_9">
                Planes&nbsp;de&nbsp;préstamo&nbsp;
                <br />
                personalizados
              </span>
            </div>
            <span class="text_14">
              Ofrecemos&nbsp;planes&nbsp;de&nbsp;préstamo&nbsp;personalizados&nbsp;basados&nbsp;en&nbsp;las&nbsp;necesidades&nbsp;y&nbsp;situación&nbsp;financiera&nbsp;específicas&nbsp;del&nbsp;usuario,&nbsp;garantizando&nbsp;la&nbsp;solución&nbsp;más&nbsp;adecuada&nbsp;para&nbsp;sus&nbsp;necesidades.
            </span>
          </div>
        </div>
      </div>
      <div class="group_15 flex-row justify-between">
        <div class="section_8 flex-col">
          <div class="text-group_15 flex-row">
            <span class="text_15">HISTORIA</span>
            <span class="text_16">NUESTRA&nbsp;HISTORIA</span>
          </div>
          <div class="block_5 flex-col"></div>
          <span class="text_17">
            Desde&nbsp;nuestros&nbsp;comienzos,&nbsp;hemos&nbsp;crecido&nbsp;significativamente,&nbsp;pasando&nbsp;de&nbsp;un&nbsp;pequeño&nbsp;inicio&nbsp;a&nbsp;convertirnos&nbsp;en&nbsp;un&nbsp;referente&nbsp;en&nbsp;nuestro&nbsp;sector.&nbsp;A&nbsp;lo&nbsp;largo&nbsp;de&nbsp;los&nbsp;años,&nbsp;hemos&nbsp;alcanzado&nbsp;importantes&nbsp;hitos&nbsp;y&nbsp;expandido&nbsp;nuestras&nbsp;operaciones,&nbsp;siempre&nbsp;con&nbsp;un&nbsp;firme&nbsp;compromiso&nbsp;con&nbsp;la&nbsp;innovación&nbsp;y&nbsp;la&nbsp;excelencia.&nbsp;Hoy&nbsp;en&nbsp;día,&nbsp;seguimos&nbsp;liderando&nbsp;el&nbsp;mercado,&nbsp;dedicados&nbsp;a&nbsp;superar&nbsp;las&nbsp;expectativas&nbsp;de&nbsp;nuestros&nbsp;clientes&nbsp;y&nbsp;a&nbsp;continuar&nbsp;nuestro&nbsp;crecimiento.
          </span>
        </div>
        <img
          class="image_14"
          referrerpolicy="no-referrer"
          src="./assets/img/SketchPng705dc9af34ffd268b0f12f33818f81941eb20f13110f9ffe4a26c7925487bb78.png"
        />
      </div>
      <div class="text-group_16 flex-row">
        <span class="text_18">CONTÁCTANOS</span>
        <span class="text_19">CONTÁCTANOS</span>
      </div>
      <div class="group_16 flex-col"></div>
      <div class="image-text_11 flex-row justify-between">
        <img
          class="label_2"
          referrerpolicy="no-referrer"
          src="./assets/img/SketchPngbf321b5b5dfbf07d93b82498f8435e427be2c605a3773798f5479a0bf2013754.png"
        />
        <div class="text-group_4">
          <span class="text_20">Número:</span>
          <span class="text_21">&nbsp;5577&nbsp;7489&nbsp;64</span>
        </div>
      </div>
      <div class="image-text_12 flex-row justify-between">
        <img
          class="label_3"
          referrerpolicy="no-referrer"
          src="./assets/img/SketchPng9cd7fc09ac131cdd82acf6062b3f8f6be6bda241684c3e006e73585a9aeec071.png"
        />
        <div class="text-group_5">
          <span class="text_22">Correo:</span>
          <span class="text_23">&nbsp;servicio&#64;casacredito.mx</span>
        </div>
      </div>
      <div class="image-text_13 flex-row justify-between">
        <img
          class="label_4"
          referrerpolicy="no-referrer"
          src="./assets/img/SketchPnga0b957a64e64d9d5bb91ec974b085d685f713be279289587381e7573f4e3fad0.png"
        />
        <div class="text-group_6">
          <span class="text_24">Facebook:</span>
          <span class="text_25">&nbsp;https://www.facebook.com/profile.php?id=61555628009525</span>
        </div>
      </div>
      <div class="image-text_14 flex-row justify-between">
        <img
          class="label_5"
          referrerpolicy="no-referrer"
          src="./assets/img/SketchPng69644e385abeaf71ad2ddc9d0836ee5fbac13c68f5e6f8b40d21be70f4a8f4da.png"
        />
        <div class="text-group_7">
          <span class="text_26">Domicilio:</span>
          <span class="text_27">
            Gobernador&nbsp;Rafael&nbsp;Rebollar&nbsp;No.&nbsp;202&nbsp;I&nbsp;Seccion,&nbsp;San&nbsp;Miguel&nbsp;Chapultepe,&nbsp;Miguel&nbsp;Hidalgo&nbsp;México,&nbsp;CIUDAD&nbsp;DE&nbsp;MEXICO,&nbsp;11850&nbsp;Mexico
          </span>
        </div>
      </div>
      <div class="group_17 flex-col">
        <div class="box_26 flex-row">
          <div class="text-group_17 flex-row">
            <span class="text_28">APLICACIÓN</span>
            <span class="text_29">NUESTRA&nbsp;APLICACIÓN</span>
          </div>
        </div>
        <div class="box_27 flex-row"><div class="box_10 flex-col"></div></div>
        <div class="box_28 flex-row">
          <img
            class="image_15"
            referrerpolicy="no-referrer"
            src="./assets/img/SketchPng97717a996721005748e40ebd9d34386dbfb58653263e4b10f348842d384d7868.png"
          />
          <span class="paragraph_10">
            HASTA&nbsp;180&nbsp;DÍAS,
            <br />
            HASTA&nbsp;$40,000&nbsp;MXN
          </span>
          <div style="cursor:pointer" class="text-wrapper_2 flex-col"><a class="text_30"   :href="appUrl">Descargar&nbsp;App</a></div>
        </div>
      </div>
      <img
        class="image_16"
        referrerpolicy="no-referrer"
        src="./assets/img/SketchPnge372752c8aae976214dd7b046acc8f28ddc9690f15253af1da055b40901a79e6.png"
      />
      <div class="group_18 flex-col"></div>
      <img
        class="image_21"
        referrerpolicy="no-referrer"
        src="./assets/img/SketchPngdf14b76b0876355347f52af169a60b5e64b1dec9f48a605408ea21b378eea744.png"
      />
      <img
        class="image_18"
        referrerpolicy="no-referrer"
        src="./assets/img/SketchPnga70f56ad13b4df049f4fa6bad2fe23e74def202e18e082cf5c9cdb0346862a69.png"
      />
      <img
        class="image_19"
        referrerpolicy="no-referrer"
        src="./assets/img/SketchPnga292df20b1fc084a37bcc94c46ccca424872133c06bf8cffc174707cc5927e87.png"
      />
    </div>
    <div class="group_19 flex-row justify-between">
      <div class="image-text_15 flex-row justify-between">
        <img
          class="image_20"
          referrerpolicy="no-referrer"
          src="./assets/img/SketchPng70ef548af9605dc0d0c9219f5d8aa4ed26d4292df624286284d633478bec4bee.png"
        />
        <a class="text-group_9" style="text-decoration: underline;" :href="privateUrl">Política&nbsp;de&nbsp;privacidad</a>
      </div>
      <span class="text_31">©&nbsp;2024&nbsp;CASACREDITO.&nbsp;TODOS&nbsp;LOS&nbsp;DERECHOS&nbsp;RESERVADOS.</span>
    </div>
  </div>
</template>
<script> 

import { appUrl, privateUrl } from "../../utils/constants.js";

export default {
  data() {
    return {appUrl,privateUrl,
      constants: {}
    };
  },
  methods: {   tabJump(name) {
      this.$router.push({ name })
    }}
};
</script>
<style scoped lang="css" src="./assets/index.response.css" />