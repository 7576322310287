
import { Toast } from 'vant';
export default {
    showToast(){
        let src = require('../assets/SketchPngd2a27ee9ae034ba7ba7dc2e9324a4bc3373d5ed79d6f41a7b6c0f53047d5797b.png')
        Toast({  
            className: 'noticeWidth',   //所加样式名
           // position: 'top',
           duration: 1500,
           message: `<div class="block_3 flex-row">
        <img
          class="thumbnail_1"
          referrerpolicy="no-referrer"
          src=${src}
        />
        <span class="text_9">Eliminado&nbsp;con&nbsp;éxito</span>
      </div>`,
           type: 'html',
         });  
    }
}